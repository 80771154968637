import Bowser from "bowser";

import './partials/hpPartials/triangles';
import './partials/hpPartials/bannerForm';

if (Bowser.name === "Internet Explorer") return;

import './partials/hpPartials/conference';
import './partials/hpPartials/hpSlider';
import './partials/hpPartials/transaction';
import './partials/hpPartials/news';
import './partials/hpPartials/partner';
import './partials/hpPartials/hpForm';
