import ScrollMagic from 'scrollmagic';
import anime from 'animejs/lib/anime.es';
import responsiveScenes from './hpResize';
import Bowser from "bowser"

// properties
let sectionChoice = document.getElementsByClassName('m-choice')[0];
const controller = new ScrollMagic.Controller();

function newAnimateTriangles() {
  const triangles = sectionChoice.querySelector('.m-choice__triangles');
  const text = sectionChoice.querySelector('.m-choice__column');
  const overlay = sectionChoice.querySelector('.m-choice__overlay');
  const video = sectionChoice.querySelector('.m-choice__video');
  let sectionChoiceDuration = 1000;

  sectionChoice.classList.add('section-scene-loaded');

  const tlOut = anime.timeline({
    easing: 'easeOutSine',
    autoplay: false
  });

  tlOut.add({
    targets: triangles,
    translateY: [0, '34.192361111vw'],
    duration: 700,
    delay: 300
  })
  .add({
    targets: video,
    translateY: [0, '-34.192361111vw'],
    duration: 700,
    delay: 300
  }, 0)
  .add({
    targets: text,
    opacity: [1, 0],
    duration: 400
  }, '-=100')
  .add({
    targets: sectionChoice,
    opacity: [1, 0],
    duration: 400
  }, '-=100');

  const tl = anime.timeline({
    'easing': 'linear',
    'autoplay': false
  });

  tl.add({
    targets: triangles,
    translateY: ['-87.281795511vh', 0],
    duration: sectionChoiceDuration
  })
  .add({
    targets: overlay,
    opacity: [1, .8],
    duration: 500
  }, 0)
  .add({
    targets: text,
    translateY: ['50%', 0],
    opacity: {
      value: [0, 1],
      easing: 'easeInQuad'
    },
    duration: 400
  }, '-=400');

  // remove opacity if below small screen size
  if (window.matchMedia('(max-width: 1023px)').matches) {
    setTimeout(() => {
      overlay.style.display = 'none';
    }, 0);
  }

  window.addEventListener('resize', () => {
    // remove opacity if below small screen size
    if (window.matchMedia('(max-width: 1023px)').matches) {
      setTimeout(() => {
        overlay.style.display = 'none';
      }, 0);
    } else {
      overlay.style.display = 'block';
    }
  })

  const scene = new ScrollMagic.Scene({
    duration: () => window.innerHeight * .87281795511,
    offset: 0
  })
  .addTo(controller);

  scene.on('progress', e => tl.seek(e.progress * tl.duration));

  const sceneOut = new ScrollMagic.Scene({
    duration: sectionChoiceDuration,
    offset: window.innerHeight * .87281795511
  })
  .setPin(sectionChoice)
  .addTo(controller);
  window.addEventListener('resize', () => sceneOut.offset(window.innerHeight * .87281795511));

  sceneOut.on('progress', e => tlOut.seek(e.progress * tlOut.duration));

  let loaded = 0;
  sceneOut.on('update', (e) => {

    if (!loaded && e.currentTarget) {

      let detail = {
        target: sectionChoice,
        duration: sectionChoiceDuration,
        mobile: matchMedia('(max-width: 1023px)')
      }

      if (Bowser.name !== "Internet Explorer") {
        const choicesBuilt = new CustomEvent('choicesLoaded', {detail});
        window.dispatchEvent(choicesBuilt);
      }
    }
    loaded = 1;
  });

  responsiveScenes.addScene(scene, matchMedia('(max-width: 1023px)'), () => tl.seek(tl.duration));
  responsiveScenes.addScene(sceneOut, matchMedia('(max-width: 1023px)'), () => tlOut.seek(0), sectionChoice);
}

document.addEventListener('DOMContentLoaded', () => {
  newAnimateTriangles();
});