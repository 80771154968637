import anime from 'animejs/lib/anime.es';

// properties
let bannerButtonText;
let videoOverlayStyle;

// show form in banner / hero section
function showBannerForm() {

  let bannerContent = document.querySelector('.m-banner__content');
  let bannerContentInner = bannerContent.querySelector('.m-banner__content--inner');
  let formMobileAnimating = 0;

  $('.v-button-form-js').on('click', function(e) {
    let matchMediaBelow =  window.matchMedia('(max-width: 768px)');

    if (!matchMediaBelow.matches) {
      $(this).find('.v-button-close').toggleClass('v-button-close--active');

      // form is already open
      if(!$(this).find('.v-button-close').hasClass('v-button-close--active')) {

        // reset styles
        $(this).css({'display': 'block'});
        $(this).find('.v-button-close')[0].style.opacity = 0;
        $(this).find('.v-button-close')[0].style.display = 'none';
        $(this).find('.v-button-text').text(bannerButtonText);
        bannerContentInner.style.overflow = 'hidden';

        anime({
          targets: '.m-banner_form',
          height: 0,
          opacity: 0,
          duration: 500,
          easing: 'easeOutQuad',
          complete: () => {
            $('.m-banner_form').removeAttr('style');
          }
        });
      } else {
        let formHeight = $('.m-banner_form__form').innerHeight();
        videoOverlayStyle = getComputedStyle(document.querySelector('.m-banner__overlay'));

        bannerButtonText = $(this).find('.v-button-text').text();
        $(this).find('.v-button-close')[0].style.opacity = 1;
        $(this).find('.v-button-close')[0].style.display = 'block';
        $(this).find('.v-button-text').text('Close Form');
        $(this).css({'display': 'flex'});
        bannerContentInner.style.overflow = 'auto';

        let tl = anime.timeline({
          duration: 500,
          easing: 'easeOutQuad'
        });

        tl.add({
          targets: '.m-banner_form',
          height: matchMediaBelow.matches ? formHeight + 100 : formHeight,
          duration: 500,
        }).add({
          targets: '.m-banner_form',
          opacity: 1,
          duration: 250,
        },'400');
      }
    } else {
      const form = document.querySelector('.m-banner_form--mobile');
      const formOpen = getComputedStyle(form).display !== 'none';

      if (formMobileAnimating) return;

      formMobileAnimating = 1;
      if (!formOpen) {
        form.style.display = 'block';
        anime({
          targets: form,
          opacity: [0, 1],
          easing: 'easeOutSine',
          duration: 400,
          complete: () => {
            form.classList.add('mobile-open');
            form.removeAttribute('style');
            formMobileAnimating = 0;
          }
        });
      } else {
        anime({
          targets: form,
          opacity: [1, 0],
          easing: 'easeOutSine',
          duration: 400,
          complete: () => {
            form.classList.remove('mobile-open');
            form.removeAttribute('style');
            formMobileAnimating = 0;
          }
        });
      }
    }
  });

  const formClose = document.querySelector('.m-banner_form__close');
  formClose.addEventListener('click', () => {
    if (formMobileAnimating) return;
    const form = document.querySelector('.m-banner_form--mobile');

    anime({
      targets: form,
      opacity: [1, 0],
      easing: 'easeOutSine',
      duration: 400,
      complete: () => {
        form.classList.remove('mobile-open');
        form.removeAttribute('style');
        formMobileAnimating = 0;
      }
    });
  });
}

document.addEventListener('DOMContentLoaded', () => {
  showBannerForm();
});