// import ScrollMagic from 'scrollmagic';
import anime from 'animejs/lib/anime.es';
import responsiveScenes from './hpResize';

function animateImageContentSection(previousScene) {
  let sectionImageContent = document.getElementsByClassName('m-conference')[0];
  let imageContentImg = sectionImageContent.getElementsByClassName('m-conference__img-bg')[0];
  let imageContentContent = sectionImageContent.getElementsByClassName('m-conference__content')[0];
  let sectionImageContentDuration = 1000;

  sectionImageContent.classList.add('section-scene-loaded');

  sectionImageContent.style.opacity = 1;

  // ------
  // IMAGE
  // ------
  let imageTL = anime.timeline({
    easing: 'easeOutSine',
    autoplay: false
  });

  let icImageObj = {
    tY: -50
  }

  let icContentObj = {
    tContentY: -50
  }

  imageTL.add({
    targets: icImageObj,
    tY: [60, -30],
    easing: 'cubicBezier(0.000, 0.805, 0.715, 0.145)',
    duration: sectionImageContentDuration,
    update: (anim) => {
      const current = anim.animations[0].currentValue;
      imageContentImg.style.transform = `translateY(${current}vh) translateY(${current}%)`;
    },
  })
  .add({
    targets: imageContentImg,
    opacity: [0, 1],
    duration: 100,
    changeComplete: () => {
      if (getComputedStyle(imageContentImg).opacity >= .5) {

        [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

        sectionImageContent.classList.add('section-active');
      } else {
        sectionImageContent.classList.remove('section-active');
      }
    }
  }, 0)
  .add({
    targets: imageContentImg,
    opacity: [1, 0],
    duration: 200,
  }, '-=200')
  imageContentImg.style.transform = `translateY(60vh) translateY(60%)`;
  // imageContentImg.style.opacity = 1;

  let controller = new ScrollMagic.Controller();
  let imageScene = new ScrollMagic.Scene({
    duration: sectionImageContentDuration,
    offset: (sectionImageContent.getBoundingClientRect().top + window.scrollY) + (previousScene.target.parentNode.classList.contains('scrollmagic-pin-spacer') || previousScene.mobile.matches ? 0 : previousScene.duration)
  })
  // .addIndicators()
  .addTo(controller);

  window.addEventListener('resize', () => {
    const el = sectionImageContent.parentNode.classList.contains('scrollmagic-pin-spacer') ? sectionImageContent.parentNode : sectionImageContent;

    imageScene.offset(el.getBoundingClientRect().top + window.scrollY + (previousScene.target.parentNode.classList.contains('scrollmagic-pin-spacer') || previousScene.mobile.matches ? 0 : previousScene.duration))
  });

  imageScene.on('progress', (event) => {
    imageTL.seek(imageTL.duration * event.progress);
  });

  // ------
  // CONTENT
  // ------
  let contentTL = anime.timeline({
    easing: 'easeOutSine',
    autoplay: false
  });

  contentTL.add({
    targets: icContentObj,
    tContentY: [-120, -50],
    update: (animationContent) => {
      if (animationContent.animations[0].animatable.target.tContentY <= -50 ) {
        const currentContentVal = animationContent.animations[0].currentValue;
        imageContentContent.style.transform = `translateY(${currentContentVal * -1}vh) translateY(${currentContentVal}%)`;
      }
    },
    duration: 500,
    delay: 200
  })
  .add({
    targets: imageContentContent,
    opacity: [0, 1],
    duration: 300
  }, 250)
  .add({
    targets: icContentObj,
    tContentY: [-50, 30],
    update: (animationContent) => {
      const currentContentVal = animationContent.animations[0].currentValue;
      if (animationContent.animations[0].animatable.target.tContentY > -50 ) {
        imageContentContent.style.transform = `translateY(${currentContentVal * -1}vh) translateY(${currentContentVal}%)`;
      }
    },
    duration: 500,
  }, '+=500')
  .add({
    targets: imageContentContent,
    opacity: [1, 0],
    duration: 500,
    changeComplete: () => {
      if (getComputedStyle(imageContentImg).opacity >= .5) {

        [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

        sectionImageContent.classList.add('section-active');
      } else {
        sectionImageContent.classList.remove('section-active');
      }
    }
  }, '-=500')
  imageContentContent.style.opacity = 0;

  let contentScene = new ScrollMagic.Scene({
    duration: sectionImageContentDuration,
    offset: (sectionImageContent.getBoundingClientRect().top + window.scrollY) + (previousScene.target.parentNode.classList.contains('scrollmagic-pin-spacer') || previousScene.mobile.matches ? 0 : previousScene.duration)
  })
  // .addIndicators()
  .setPin(sectionImageContent)
  .addTo(controller);

  window.addEventListener('resize', () => {
    const el = sectionImageContent.parentNode.classList.contains('scrollmagic-pin-spacer') ? sectionImageContent.parentNode : sectionImageContent;
    contentScene.offset(el.getBoundingClientRect().top + window.scrollY + (previousScene.target.parentNode.classList.contains('scrollmagic-pin-spacer') || previousScene.mobile.matches ? 0 : previousScene.duration))
  });

  contentScene.on('progress', (event) => {
    // imageContentContent.style.opacity =  event.progress;
    contentTL.seek(contentTL.duration * event.progress);
  });

  let loaded = 0;
  contentScene.on('update', (e) => {

    if (!loaded && e.currentTarget) {
      let detail = {
        target: sectionImageContent,
        duration: sectionImageContentDuration,
        mobile: matchMedia('(max-width: 1350px)')
      }

      const imageContentBuilt = new CustomEvent('imageContentLoaded', {detail});
      window.dispatchEvent(imageContentBuilt);

      setTimeout(() => {
        if (getComputedStyle(imageContentImg).opacity >= .5) {
          [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

          sectionImageContent.classList.add('section-active');
        } else {
          sectionImageContent.classList.remove('section-active');
        }
      }, 150);
    }
    loaded = 1;
  });

  contentScene.on('end', (event) => {
    // create custom event
    const animationEnd = new CustomEvent('imageContentAnimationEnd', {detail: event.scrollDirection});
    // dispatch to listener
    window.dispatchEvent(animationEnd);
  });

  const cb = () => {
    // reset styles
    sectionImageContent.removeAttribute('style');
    imageContentImg.removeAttribute('style');
    imageContentContent.removeAttribute('style');
    sectionImageContent.style.opacity = 1;
  }

  responsiveScenes.addScene(imageScene, matchMedia('(max-width: 1200px)'), cb);
  responsiveScenes.addScene(contentScene, matchMedia('(max-width: 1200px)'), cb, sectionImageContent);
}

window.addEventListener('choicesLoaded', (e) => {
  animateImageContentSection(e.detail);
});
