// import ScrollMagic from 'scrollmagic';
import anime from 'animejs/lib/anime.es';
import responsiveScenes from './hpResize';

const transactionAnimation = (previousScene) => {
  let sectionTransaction = document.getElementsByClassName('m-transaction')[0];
  let navBar = document.getElementsByClassName('a-nav')[0];
  let navBarBg = navBar.getElementsByClassName('a-nav--light-bg')[0];
  let isHomepage = navBar.classList.contains('a-nav--homepage');
  let transactionTitle = document.getElementsByClassName('m-transaction__content-title-container')[0];
  let transactionBgImg = document.getElementsByClassName('m-transaction__bg--img')[0];
  let transactionSlider = document.getElementsByClassName('m-transaction__slider')[0];
  let transactionCTA = document.getElementsByClassName('m-transaction__cta')[0];

  sectionTransaction.classList.add('section-scene-loaded');

  const tl = anime.timeline({
    easing: 'easeOutSine',
    autoplay: false
  });

  tl.add({
    targets: sectionTransaction,
    opacity: [0, 1],
    duration: 250,
    changeComplete: () => {
      if (getComputedStyle(sectionTransaction).opacity >= .5) {

        [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

        sectionTransaction.classList.add('section-active');
      } else {
        sectionTransaction.classList.remove('section-active');
      }
    }
  })
  .add({
    targets: navBarBg,
    opacity: [1, 0],
    duration: 50,
    changeComplete: () => {
      if (window.scrollY === 0) {
        navBarBg.style.opacity = 0;
      }

      if (getComputedStyle(navBarBg).opacity == 0) {
        navBar.classList.remove('a-nav--light');
      } else {
        navBar.classList.add('a-nav--light');
      }
    }
  }, 0)
  .add({
    targets: transactionTitle,
    translateY: [-50, 0],
    opacity: [0, 1],
    duration: 500
  }, 0)
  .add({
    targets: transactionSlider,
    translateY: [50, 0],
    opacity: [0, 1],
    duration: 500
  }, 0)
  .add({
    targets: transactionCTA,
    translateY: [50, 0],
    opacity: [0, 1],
    duration: 500
  }, 0)
  .add({
    targets: transactionTitle,
    translateY: [0, -50],
    opacity: [1, 0],
    duration: 250,
  }, '+=1500')
  .add({
    targets: sectionTransaction,
    opacity: [1, 0],
    duration: 250
  })
  .add({
    targets: transactionSlider,
    translateY: [0, 50],
    opacity: [1, 0],
    duration: 500
  }, '-=250')
  .add({
    targets: transactionCTA,
    translateY: [0, 50],
    opacity: [1, 0],
    duration: 500
  }, '-=500')
  .add({
    targets: navBarBg,
    opacity: [0, 1],
    duration: 250,
    changeComplete: () => {
      if (window.scrollY === 0) {
        navBarBg.style.opacity = 0;
      }

      if (getComputedStyle(sectionTransaction).opacity >= .5) {

        [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

        sectionTransaction.classList.add('section-active');
      } else {
        sectionTransaction.classList.remove('section-active');
      }

      if (getComputedStyle(navBarBg).opacity == 1) {
        navBar.classList.add('a-nav--light');
      } else {
        navBar.classList.remove('a-nav--light');
      }
    }
  }, '-=500');

  if (window.scrollY === 0) {
    navBarBg.style.opacity = 0;
    navBar.classList.remove('a-nav--light');
  }
  navBarBg.classList.remove('scene-loading');

  const bgTL = anime.timeline({
    autoplay: false
  });

  bgTL.add({
    targets: transactionBgImg,
    top: [0, -500],
    easing: 'linear'
  })

  let controller = new ScrollMagic.Controller();
  let scene = new ScrollMagic.Scene({
    duration: 1500,
    offset: sectionTransaction.getBoundingClientRect().top + window.scrollY
  })
  .setPin(sectionTransaction)
  // .addIndicators()
  .addTo(controller);

  window.addEventListener('resize', () => {
    const el = sectionTransaction.parentNode.classList.contains('scrollmagic-pin-spacer') ? sectionTransaction.parentNode : sectionTransaction;
    scene.offset(el.getBoundingClientRect().top + window.scrollY);
  })

  scene.on('progress', (event) => {
    bgTL.seek(bgTL.duration * event.progress * 1);
    tl.seek(tl.duration * event.progress);
  });

  let loaded = 0;
  scene.on('update', (e) => {
    if (!loaded && e.currentTarget) {



      setTimeout(() => {
        if (window.scrollY === 0) {
          navBarBg.style.opacity = 0;
        }

        if (getComputedStyle(navBarBg).opacity == 1) {
          navBar.classList.add('a-nav--light');
        } else {
          navBar.classList.remove('a-nav--light');
        }

        if (getComputedStyle(sectionTransaction).opacity >= .5) {
          [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

          sectionTransaction.classList.add('section-active');
        } else {
          sectionTransaction.classList.remove('section-active');
        }
      }, 50);

      let detail = {
        target: sectionTransaction,
        duration: scene.duration(),
        mobile: matchMedia('(max-width: 1200px)')
      }

      const transactionBuilt = new CustomEvent('transactionLoaded', {detail});
      window.dispatchEvent(transactionBuilt);
    }
    loaded = 1;
  });

  const cb = () => {
    sectionTransaction.removeAttribute('style');
    transactionBgImg.removeAttribute('style');
    transactionSlider.removeAttribute('style');
    transactionCTA.removeAttribute('style');
    navBarBg.style.opacity = 1;
    navBar.classList.add('a-nav--light');
  }

  responsiveScenes.addScene(scene, matchMedia('(max-width: 1200px)'), cb, sectionTransaction);
}

window.addEventListener('imageContentLoaded', (e) => {
  transactionAnimation(e.detail);
});