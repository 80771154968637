// import ScrollMagic from 'scrollmagic';
import anime from 'animejs/lib/anime.es';
import responsiveScenes from './hpResize';

const animateHpForm = (previousScene) => {
  let sectionForm = document.getElementsByClassName('m-form')[0];
  let sectionFormBg = sectionForm.getElementsByClassName('m-form__bg')[0];
  let sectionFormTitleContainer = document.querySelector('.m-form__content .container');
  let formContainer = document.getElementsByClassName('m-form__content--inner')[0];
  let isBelowLargeMedia = window.matchMedia('(max-width: 1200px');

  const sectionFormParent = sectionForm.parentNode;
  let sectionFormTop = 0;

  if (sectionFormParent.classList.contains('scrollmagic-pin-spacer')) {
    sectionFormTop = sectionFormParent.getBoundingClientRect().top;
  } else {
    sectionFormTop = sectionForm.getBoundingClientRect().top;
  }

  let formTL = anime.timeline({
    easing: 'linear',
    autoplay: false
  });

  formTL.add({
    targets: sectionForm,
    opacity: [0, 1],
    translateY: ['-5%', '0%'],
    duration: 500,
  })
  .add({
    targets: sectionFormTitleContainer,
    translateY: [100, 0],
    opacity: [0, 1],
    duration: 500
  })
  .add({
    targets: sectionFormBg,
    opacity: [0, 1],
    duration: 1000
  }, 100)
  .add({
    targets: formContainer,
    translateY: [50, 0],
    opacity: [0, 1],
    duration: 1000
  }, 400);

  let controller = new ScrollMagic.Controller();
  let formScene = new ScrollMagic.Scene({
    duration: 300,
    offset: (sectionForm.getBoundingClientRect().top + window.scrollY) + (previousScene.target.parentNode.classList.contains('scrollmagic-pin-spacer') || previousScene.mobile.matches ? 0 : previousScene.duration) - (window.innerHeight / 3)
  })
  // .addIndicators()
  .addTo(controller);

  formScene.on('start', (e) => {

    if (e.scrollDirection === "FORWARD") {

      [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

      sectionForm.classList.add('section-active');
    } else {
      document.getElementsByClassName('m-partner')[0].classList.add('section-active');
      document.getElementsByClassName('m-news')[0].classList.add('section-active');
      sectionForm.classList.remove('section-active');
    }
  });

  formScene.on('progress', (event) => {
    formTL.seek(event.progress * (formTL.duration * 2));
  });

  let loaded = 0;
  formScene.on('update', (e) => {

    if (!loaded && e.currentTarget) {
      setTimeout(() => {
        if (getComputedStyle(sectionForm).opacity >= .5) {
          [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

          sectionForm.classList.add('section-active');
        } else {
          sectionForm.classList.remove('section-active');
        }
      }, 50);
    }
    loaded = 1;
  });

  window.addEventListener('resize', () => {
    const el = sectionForm.parentNode.classList.contains('scrollmagic-pin-spacer') ? sectionForm.parentNode : sectionForm;
    formScene.offset((el.getBoundingClientRect().top + window.scrollY) + (previousScene.target.parentNode.classList.contains('scrollmagic-pin-spacer') || previousScene.mobile.matches ? 0 : previousScene.duration) - (window.innerHeight / 3))
  });

  window.addEventListener('partnerAnimationEnd', (e) => {

    // make sure not to flash sectionForm
    // if next section (.m-news) has passed
    if (e.detail == "FORWARD") {
      enterAnimation();
    } else {
      leaveAnimation();
    }
  });

  const cb = () => {
    // reset styles
    sectionForm.removeAttribute('style');
    sectionFormBg.removeAttribute('style');
    sectionFormTitleContainer.removeAttribute('style');
    formContainer.removeAttribute('style');
  }

  responsiveScenes.addScene(formScene, matchMedia('(max-width: 1200px)'), cb);
}

window.addEventListener('partnersLoaded', (e) => {
  animateHpForm(e.detail);
})