import anime from 'animejs/lib/anime.es';
import responsiveScenes from './hpResize';

const animateNewsSection = (previousScene) => {
  const newsSection = document.getElementsByClassName('m-news')[0];
  const newsContainer = newsSection.querySelectorAll('.container');
  const newsSectionTitle = document.getElementsByClassName('m-news__hero-content')[0];
  const newsSectionImage = newsSection.querySelector('.m-news__hero-img');
  const sectionNewsParent = newsSection.parentNode;
  let sectionNewsTop = 0;

  newsSection.classList.add('section-scene-loaded');

  let newsTL = anime.timeline({
    autoplay: false,
    easing: 'easeOutSine'
  });

  newsTL.add({
    targets: newsSectionTitle,
    opacity: [0, 1],
    duration: 50,
    changeComplete: () => {
      if (getComputedStyle(newsSectionTitle).opacity > .1) {

        [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

        newsSection.classList.add('section-active');
      } else {
        newsSection.classList.remove('section-active');
      }
    }
  }, 0)
  .add({
    targets: [newsContainer[1], newsSectionImage],
    opacity: [0, 1],
    duration: 100
  }, '-=50')
  .add({
    targets: newsContainer,
    translateY: [50, 0],
    duration: 300
  }, '-=400')
  .add({
    targets: newsSectionTitle,
    translateY: ['50%', '30.6122449%'],
    bottom: ['50%', 0],
    duration: 300
  }, '-=150')
  .add({
    targets: newsSectionImage,
    opacity: [1, 0],
    duration: 150
  }, '-=75');


  let controller = new ScrollMagic.Controller();
  let newsScene = new ScrollMagic.Scene({
    duration: 625,
    offset: newsSection.getBoundingClientRect().top + window.scrollY
  })
  // .addIndicators()
  .addTo(controller);

  window.addEventListener('resize', () => {
    const el = newsSection.parentNode.classList.contains('scrollmagic-pin-spacer') ? newsSection.parentNode : newsSection;
    newsScene.offset(el.getBoundingClientRect().top + window.scrollY)
  });

  newsScene.on('progress', (event) => {
    newsTL.seek(newsTL.duration * event.progress)
  });

  // window.addEventListener('resize', () => {
  //   const el = newsSection.parentNode.classList.contains('scrollmagic-pin-spacer') ? newsSection.parentNode : newsSection;
  //   newsScene.offset(el.getBoundingClientRect().top + window.scrollY + (previousScene.target.parentNode.classList.contains('scrollmagic-pin-spacer') || previousScene.mobile.matches ? 0 : previousScene.duration) - window.innerHeight)
  // });

  let loaded = 0;
  newsScene.on('update', (e) => {

    if (!loaded && e.currentTarget) {

      let detail = {
        target: newsSection,
        duration: newsScene.duration(),
        mobile: matchMedia('(max-width: 1200px)')
      }

      const newsBuilt = new CustomEvent('newsLoaded', {detail});
      window.dispatchEvent(newsBuilt);

      setTimeout(() => {
        if (getComputedStyle(newsSectionTitle).opacity != 0) {
          [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

          newsSection.classList.add('section-active');
        } else {
          newsSection.classList.remove('section-active');
        }
      }, 50);
    }
    loaded = 1;
  });

  const cb = () => {
    [...newsContainer].forEach(c => c.removeAttribute('style'));
    newsSectionTitle.removeAttribute('style');
    newsSectionImage.removeAttribute('style');
  }

  responsiveScenes.addScene(newsScene, matchMedia('(max-width: 1200px)'), cb);
}

window.addEventListener('transactionLoaded', (e) => {
  animateNewsSection(e.detail);
})