import anime from 'animejs/lib/anime.es';
import responsiveScenes from './hpResize';

const animatePartnerSection = (previousScene) => {
  const partnerSection = document.getElementsByClassName('m-partner')[0];
  const partnerSectionImg = document.getElementsByClassName('m-partner__img')[0];
  const partnerSectionContent = document.getElementsByClassName('m-partner__content')[0];
  const partnerSectionParent = partnerSection.parentNode;
  let partnerSectionTop = 0;

  // add animejs
  const partnerTL = anime.timeline({
    autoplay: false,
    easing: 'easeOutSine'
  });

  const stuckTL = anime.timeline({
    autoplay: false,
    easing: 'easeOutSine'
  });

  let imgObj = {
    imgY: -20
  }
  let contentObj = {
    contentY: -20
  }

  partnerTL.add({
    targets: partnerSectionImg,
    opacity: [0, 1],
    duration: 200
  });

  stuckTL.add({
    targets: partnerSectionContent,
    translateY: ['60%', '-60%'],
    easing: 'cubicBezier(.07,.88,.9,.2)',
    duration: 1000
  })
  .add({
    targets: partnerSectionContent,
    opacity: [0, 1],
    duration: 200,
    // changeComplete: () => {
    //   if (getComputedStyle(partnerSectionContent).opacity != 0) {

    //     [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

    //     partnerSection.classList.add('section-active');
    //   } else {
    //     document.getElementsByClassName('m-news')[0].classList.add('section-active');
    //     partnerSection.classList.remove('section-active');
    //   }
    // }
  }, 0)
  .add({
    targets: partnerSectionContent,
    opacity: [1, 0],
    duration: 200,
    // changeComplete: () => {
    //   if (getComputedStyle(partnerSectionContent).opacity != 0) {

    //     [...document.querySelectorAll('section')].forEach(el => el.classList.remove('section-active'));

    //     partnerSection.classList.add('section-active');
    //   } else {
    //     partnerSection.classList.remove('section-active');
    //   }
    // }
  }, '-=200')
  .add({
    targets: partnerSectionImg,
    translateY: [0, '-50%'],
    opacity: [1, 0],
    duration: 200
  }, '-=200');
  partnerSectionImg.style.opacity = 0;

  // add scrollMagic
  if (partnerSectionParent.classList.contains('scrollmagic-pin-spacer')) {
    partnerSectionTop = partnerSectionParent.getBoundingClientRect().top;
  } else {
    partnerSectionTop = partnerSection.getBoundingClientRect().top;
  }

  let controller = new ScrollMagic.Controller();
  let partnerScene = new ScrollMagic.Scene({
    duration: 200,
    offset: partnerSection.getBoundingClientRect().top + window.scrollY - window.innerHeight / 1.5
  })
  // .addIndicators()
  .addTo(controller);

  const stuckScene = new ScrollMagic.Scene({
    duration: 1500,
    offset: partnerSection.getBoundingClientRect().top + window.scrollY
  })
  .setPin(partnerSection)
  .addTo(controller);

  window.addEventListener('resize', () => {
    const el = partnerSection.parentNode.classList.contains('scrollmagic-pin-spacer') ? partnerSection.parentNode : partnerSection;
    partnerScene.offset(el.getBoundingClientRect().top + window.scrollY - window.innerHeight / 1.5);
    stuckScene.offset(el.getBoundingClientRect().top + window.scrollY);
  });

  partnerScene.on('start', (event) => {
    if (event.scrollDirection === "FORWARD") {
      [...document.querySelectorAll('section')].forEach(el => {
        if (!el.classList.contains('m-news')) el.classList.remove('section-active');
      });
      partnerSection.classList.add('section-active');
    } else {
      partnerSection.classList.remove('section-active');
    }
  });
  partnerScene.on('progress', (event) => {
    partnerTL.seek(partnerTL.duration * event.progress);
  });

  stuckScene.on('progress', (event) => {
    stuckTL.seek(stuckTL.duration * event.progress);
  });

  let loaded = 0;
  partnerScene.on('update', (e) => {

    if (!loaded && e.currentTarget) {

      let detail = {
        target: partnerSection,
        duration: partnerScene.duration(),
        mobile: matchMedia('(max-width: 1200px)')
      }

      const partnersBuilt = new CustomEvent('partnersLoaded', {detail});
      window.dispatchEvent(partnersBuilt);

      setTimeout(() => {
        if (getComputedStyle(partnerSectionContent).opacity >= .5) {
          [...document.querySelectorAll('section')].forEach(el => {
            if (!el.classList.contains('m-news')) el.classList.remove('section-active');
          });

          partnerSection.classList.add('section-active');
        } else {
          partnerSection.classList.remove('section-active');
        }
      }, 50);
    }
    loaded = 1;
  });

  const cb = () => {
    partnerSectionContent.removeAttribute('style');
    partnerSectionImg.removeAttribute('style');
  }

  responsiveScenes.addScene(partnerScene, matchMedia('(max-width: 1200px)'), cb);
  responsiveScenes.addScene(stuckScene, matchMedia('(max-width: 1200px)'), cb, partnerSection);
}

window.addEventListener('newsLoaded', (e) => {
  animatePartnerSection(e.detail);
})