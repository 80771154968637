import anime from 'animejs/lib/anime.es';

// slick slider
function initSlider() {

  $('.m-transaction__slider--arrow-left').on('click', () => {
    console.log('left arr')
    $('.m-transaction__slider--btn-left.slick-arrow').click();
  })
  $('.m-transaction__slider--arrow-right').on('click', () => {
    console.log('right arr')
    $('.m-transaction__slider--btn-right.slick-arrow').click();
  })

  // slider settings
  $('#slider').slick({
    autoplay: false,
    speed: 500,
    dots: true,
    appendDots: $('.m-transaction__pagination'),
    nextArrow: $('.m-transaction__slider--btn-right'),
    prevArrow: $('.m-transaction__slider--btn-left'),
  });

  // initially setting opacity 0 in css prevents users
  // from touch sliding and seeing next/prev slide
  $('.m-transaction__slider-cta').eq(1).css({
    'opacity': 1
  });
  $('.m-transaction__slider-container--left').eq(1).css({
    'opacity': 1
  });
  $('.m-transaction__slider-container--right').eq(1).css({
    'opacity': 1
  });

  // remove dot number indicators
  let dots = $('.slick-dots').find('li');

  // override default styles
  $('.slick-dots')[0].style.display = 'flex';
  $('.slick-dots')[0].style.listStyle = 'none';
  $('.slick-dots')[0].style.paddingLeft = 0;
  $('.slick-dots')[0].style.marginBottom = 0;
  $('.slick-active')[0].style.backgroundColor = '#fff';

  for (let i = 0; i < dots.length; i++) {
    const dotBtn = $(dots[i]);
    dotBtn.find('button')[0].innerText = '';
  }

  $('#slider').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let parentHeight = document.querySelector('.m-transaction__slider-container').offsetHeight;
    let $slideCurrent = $('div.slick-slide[data-slick-index="' + currentSlide + '"]')[0];
    let $slideNext = $('div.slick-slide[data-slick-index="' + nextSlide + '"]')[0];
    let infoLeft = $slideNext.querySelector('.m-transaction__slider-container--left');
    let infoRight = $slideNext.querySelector('.m-transaction__slider-container--right');
    let infoCtaCurrent = $slideNext.querySelector('.m-transaction__slider-cta');

    $('.m-transaction__slider--btn-right').eq(0).css({
      'height': parentHeight
    });
    $('.m-transaction__slider--btn-left').eq(0).css({
      'height': parentHeight
    });
    $('.slick-track').eq(0).css({
      'height': parentHeight
    });

    $('.m-transaction__slider-overlay--vjs').eq(0).css({
      // 'opacity': 1,
      'height': parentHeight,
      'position': 'absolute'
    });

    $(infoRight).css({
      'transform': 'translateX(30%)',
      'opacity': 0
    });
    $(infoLeft).css({
      'transform': 'translateX(30%)',
      'opacity': 0
    });
    $(infoCtaCurrent).css({
      'opacity': 0
    });

    let currentIndex = (currentSlide + 1) === slick.$slides.length ? 0 : currentSlide + 1;

    anime({
      targets: '.m-transaction__slider-overlay--vjs',
      width: '100%',
      opacity: 0,
      duration: 0,
      easing: 'easeInSine',
      complete: () => {
        console.log('completed');
      }
    });
    anime({
      targets: `.slick-slide[data-slick-index="${currentSlide}"]`,
      opacity: 0,
      duration: 500,
    });
  });

 $('#slider').on('afterChange', (event, slick, currentSlide, nextSlide) => {

    // make background visibile
    $('.slick-list').eq(0).css({
      'opacity': 1
    });

    // apply bg color to active li element
    [...slick.$dots[0].children].forEach((li) => {

      li.style.backgroundColor = 'transparent';

      if (li.classList.contains('slick-active')) {
        li.style.backgroundColor = '#fff';
      }
    })

    let $slideCurrent = $('div.slick-slide[data-slick-index="' + currentSlide + '"]')[0];
    let infoRightCurrent = $slideCurrent.querySelector('.m-transaction__slider-container--right');
    let infoLeftCurrent = $slideCurrent.querySelector('.m-transaction__slider-container--left');
    let infoCtaCurrent = $slideCurrent.querySelector('.m-transaction__slider-cta');

    let currentIndex = (currentSlide + 1) === slick.$slides.length ? 0 : currentSlide + 1;
    for (let i = 0; i < slick.$slides.length; i++) {
      $('div.slick-slide[data-slick-index="' + i + '"]').eq(0).css('opacity', 1);
    }

    anime({
      targets: '.m-transaction__slider-overlay--vjs',
      opacity: 1,
      duration: 0,
      easing: 'easeOutSine'
    });
    anime({
      targets: infoLeftCurrent,
      translateX: 0,
      opacity: 1,
      duration: 300,
      easing: 'easeOutSine'
    });
    anime({
      targets: infoRightCurrent,
      translateX: 0,
      opacity: 1,
      duration: 300,
      easing: 'easeOutSine',
    });
    anime({
      targets: infoCtaCurrent,
      opacity: 1,
      translateX: [-20, 0],
      duration: 500,
      easing: 'easeOutSine'
    });
 });
}

document.addEventListener('DOMContentLoaded', () => {
  initSlider();
});